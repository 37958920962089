@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(assets/font/font.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply m-0 text-xl lg:text-2xl xl:text-36 text-primary font-bold;
}

a {
  @apply no-underline text-primary;
  cursor: pointer;
}

.active_tab {
  @apply border-b-2 border-primary block;
}

.a:after {
  @apply border-b-2 border-primary block;
  content: "";
  width: inherit;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

a:hover:after {
  transform: scaleX(1);
}

input {
  @apply border border-border rounded-10 w-full p-3 lg:px-[27px] lg:py-[14px] text-primary font-semibold focus:outline-none text-sm md:text-xl;
}
textarea {
  @apply border border-border rounded-10 w-full p-3 lg:px-[27px] lg:py-[14px] text-primary font-semibold focus:outline-none text-sm md:text-xl;
}
.hero-section {
  @apply lg:min-h-[calc(100vh-259px)] min-h-[calc(100vh-86px)] h-full  xl:py-10 py-8 flex items-center;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #e3f2ff !important;
}
.react-tel-input .selected-flag {
  padding: 0 !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: #bce0ff !important;
}
.react-tel-input .selected-flag {
  border-radius: 10px 0 0 10px !important;
  width: 50px !important;
  padding-left: 15px !important;
}
.react-tel-input .form-control {
  padding-left: 60px !important;
}

button {
  @apply bg-primary text-white font-semibold w-full p-[14px] rounded-10 hover:bg-primary/95;
}
.btns {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.containers {
  @apply max-w-[1280px] px-5 xl:px-0 mx-auto w-full;
}

/* Login Page CSS */

.login-section {
  @apply h-screen w-full;
  background: linear-gradient(180deg, #016bcd 0%, #5bdad5 100%);
}

.login-parts {
  @apply p-5 flex items-center justify-center h-full;
}

.login-cards {
  @apply flex items-center justify-center flex-col gap-8 md:gap-[67px];
}

.cards {
  @apply w-full bg-white rounded-14 p-5 md:p-10;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.login-content {
  @apply max-w-[399px] w-full;
}
.overwrite-root-notistack {
  max-width: 500px !important;
}

.close-menu,
.open-menu {
  position: absolute;
  cursor: pointer;
  display: none;
}

.open-menu {
  top: 45px;
  right: 20px;
  transform: translateY(-50%);
}

.close-menu {
  top: 35px;
  right: 50px;
}

#check {
  display: none;
}

@media (max-width: 1024px) {
  .menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    background-color: #fff;
    backdrop-filter: blur(10px) !important;
    color: #00386c;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    font-size: 24px;
    /* box-shadow: rgba(0, 0, 0, 0.16) -5px 0px 6px,
        rgba(0, 0, 0, 0.23) -5px 0px 6px; */
  }

  .menu li a {
    padding: 10px;
    display: inline-block;
  }

  .close-menu,
  .open-menu {
    display: block;
  }

  .close-menu {
    top: 20px;
    left: 20px;
  }
  .close-menu svg {
    height: 24px;
    width: 24px;
  }

  #check:checked ~ .menu {
    right: 0;
  }
}
